<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2022-05-07 14:57:21
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-11-25 10:42:45
-->

<template>
  <el-card class="box">
    <div class="avatar">
      <el-avatar
        :src="model.avatar_pic ? model.avatar_pic : avatar"
      ></el-avatar>
    </div>
    <el-descriptions>
      <el-descriptions-item label="姓名">{{ model.name }}</el-descriptions-item>
      <el-descriptions-item label="账号">{{
        model.account
      }}</el-descriptions-item>
      <el-descriptions-item label="手机">{{ model.mobile_phone }}</el-descriptions-item>

      <el-descriptions-item label="角色">{{
        model.is_super ? "超级管理员" : model.roles.map((x) => x.name).join("")
      }}</el-descriptions-item>
      <el-descriptions-item label="创建时间">{{ model.create_at }}</el-descriptions-item>
    </el-descriptions>
  </el-card>
</template>
 
<script>
import avatar from "@/assets/avatar.png";
import center_api from "@/http/center_api";
export default {
  data() {
    return {
      //本地默认头像
      avatar: avatar,
      //个人资料
      model: {
        roles: [],
      },
    };
  },
  created() {
    /**
     * 获取个人资料
     */
    center_api.get_my().then((res) => {
      if (res.code == 0) {
        this.model = res.data;
      }
    });
  },
  methods: {},
};
</script>

<style   scoped>
.avatar {
  margin-bottom: 30px;
}
</style>