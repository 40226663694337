/*
 * @Description: 获取个人资料
 * @Author: 琢磨先生
 * @Date: 2022-11-25 10:26:43
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-11-25 10:27:59
 */

import http from "./axios_init";
export default {
    /**
     * 获取个人资料
     * @returns
     */
     get_my() {
      return http.get("admin/v1/center");
    },
  };
  